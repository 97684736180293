import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import IncreaseProtectionImg from "../../assets/images/spam-blocker/increased-protection.webp"
import IncreaseProtectionMImg from "../../assets/images/spam-blocker/increased-protection-m.webp"
import SpamBlockerMImg from "../../assets/images/spam-blocker/spam-blocker-m.webp"
import BlockListMImg from "../../assets/images/spam-blocker/block-list-m.webp"
import EntireDomainMImg from "../../assets/images/spam-blocker/block-entire-domain-m.webp"
import { Link } from "gatsby"

const SpamBlocker = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="BlueMail’s Spam Management Tool | BlueMail App"
        postDescription="We provide a tool to Block emails that your provider’s “spam filter” did not catch, preventing you from accidentally opening or downloading anything harmful to your device."
        postImage="/img/OG/og_image-security-center-block-list.png"
        postURL="security/block-list"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid spam-blocker w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  <span>Spam</span> Blocker
                </h1>
                <p>
                  BlueMail offers a Spam Management tool to supplement your
                  provider’s existing spam filter. The purpose of the Block List
                  is to provide a tool to Block emails that your provider’s
                  “spam filter” did not catch.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={SpamBlockerMImg} alt="Spam blocker" />
            </div>
          </div>
        </div>
        <div className="container-fluid block-list w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>Block</span> List
                </h2>
                <p>
                  Blocked emails will be added to your Block List (spam list)
                  and future emails from that Sender, Domain, or Suffix (as per
                  your choice) will go directly to the Spam folder, bypassing
                  your Inbox.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={BlockListMImg} alt="Block list" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  <span>Increased</span> Protection
                </h2>
                <p>
                  Protecting against malicious emails, can help to prevent
                  harmful emails from reaching your inbox and potentially
                  causing harm to your computer or network.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="d-992"
                src={IncreaseProtectionImg}
                alt="Increased Protection"
              />
              <img
                className="w-991 mt-3 mb-5"
                src={IncreaseProtectionMImg}
                alt="Increased Protection mobile"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid entire-domain w-707 r mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h2>
                  Block an <span>entire Domain</span>
                </h2>
                <p>
                  You can then choose between blocking the sender or the whole
                  domain. This email and all future emails from that sender or
                  domain will be blocked.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={EntireDomainMImg} alt="Entire domain" />
            </div>
          </div>
        </div>
        <div className="container manage-spam">
          <Link to="https://bluemail.me/help/manage-spam/">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 text-center mb-100">
                <h2>
                  <span>Manage</span> Spam
                </h2>
                <p>
                  To start using the BlueMail Spam Filter go to your Inbox
                  folder and right-click a specific email.
                </p>
                <p className="l-more">
                  <span>Learn More</span>
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default SpamBlocker

export const query = graphql`
  query SpamBlockerPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
